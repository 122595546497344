<template>
  <form @submit.prevent="SignIn">
    <div class="flex flex-col gap-y-3 mx-auto max-w-xs mt-8">
      <input
        type="email"
        v-model="loginForm.email"
        required
        class="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:bg-neutral-800 rounded-full text-sm font-normal h-11 px-4 py-3 mt-1"
        placeholder="Email@example.com"
      />
      <input
        type="password"
        required
        v-model="loginForm.password"
        class="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:bg-neutral-800 rounded-full text-sm font-normal h-11 px-4 py-3 mt-1"
        :placeholder="$t('your_password')"
      />
      <div v-if="err_msg" class="text-xs text-red-500 flex ml-1 mt-2 mb-4">
        ⚠️ {{ err_msg }}
      </div>
      <ElementsButtonSubmit :loading="loading"> {{ $t('sign_in') }} </ElementsButtonSubmit>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/stores/auth";
import { useConfigStore } from "~/stores/config";
const props = defineProps({
  redirect: {
    type: Boolean,
    default: false,
  },
});
const store = useAuthStore();
const { loginForm } = storeToRefs(store);
const { auth_modal, auth_step } = storeToRefs(useConfigStore());
const err_msg = ref("");
const loading = ref(false);
const SignIn = () => {
  loading.value = true;
  store
    .login(loginForm.value)
    .then((res) => {
      loading.value = false;
      auth_modal.value = false;
      if (props.redirect) {
        navigateTo("/dashboard");
      }
    })
    .catch((err) => {
      loading.value = false;
      if (err.data.message) {
        err_msg.value = err.data.message;
      } else {
        err_msg.value = "";
      }
    });
};
</script>
